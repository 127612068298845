.subscribe-form-2 {
  @media #{$xs-layout} {
  height: 15rem;
  padding:2rem;
  padding-top: 2rem;
  background-size: cover;
}
  display: flex;
  flex-direction: column;
  background-size: contain;
  padding: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  input {
    background: #fff;
    border-radius: 25px;
    border: none;
    height: 45px;
    padding: 2px 50px 2px 0;
    font-size: 14px;
    color: $theme-color;
    border: 1.3px solid $theme-color;
    padding-left: 1rem;
    margin-bottom: 1rem;
    &::placeholder{
      color:$third-color;
    }
    @media #{$xs-layout} {
      padding-left: 1rem;
    }
  }
  .mc-news {
    display: none;
  }

    button {
      background: $btn-color;
      border: none;
      height: 45px;
      padding: 0;
      border-radius: 25px;
      margin:auto;
      font-size: 30px;
      color: #fff;
      transition: 0.3s;
      font-size: 22.18px;
      font-family: $montmedium;
      padding-right: 1rem;
      padding-left: 1rem;
      @media #{$xs-layout} {
        font-size: 20px;
      }
      &:hover {
        color:$theme-color;
      }
    }
}

.subscribe-area{
  .bor {
    border: 1px solid $theme-color;
    width: 100%;
    height: inherit;
    position: absolute;
  }
  .subscribe-container{
    position: relative;
    height: 25rem;
  }
  h3{
    color: #8A8989;
    font-size:40px;
    font-family: $montmedium;
    line-height: 28px;
    @media #{$xs-layout} {
      font-size: large;
      text-align: start;
    }
  }
  #italic{
    color: $theme-color;
    font-family: Montserrat-BoldItalic;
    font-size:40px;
    @media #{$xs-layout} {
      font-size: large;
      text-align: start;
    }
  }
}

.subscribe-area-3.pb-100 {
  @media #{$xs-layout} {
    padding-bottom: 60px !important;
  }
}
